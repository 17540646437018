import * as React from 'react';

import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import { InviteStatus, Status } from '../../generated/graphql';
import useUpdateUserEvent from '../../hooks/mutations/useUpdateUserEvent';
import useUpdateUserEventInvite from '../../hooks/mutations/useUpdateUserEventInvite';
import useGql from '../../hooks/useGql';
import { useLogin } from '../../hooks/useLogin';

export type EventInvite = {
  id: string;
  status: InviteStatus;
};
interface InviteStatusButtonProps {
  userId?: string;
  eventId: string;
  eventInvite?: EventInvite;
  userActiveStatus?: Status;
  currentUrl: string;
}
interface MutateUserEvent {
  eventId: string;
  userId: string;
  status: Status;
}

export default function InviteStatusButton({
  userId,
  eventId,
  eventInvite,
  currentUrl,
  userActiveStatus,
}: InviteStatusButtonProps) {
  const [selectedStatus, setSelectedStatus] = React.useState<InviteStatus>(
    userActiveStatus
      ? userActiveStatus === Status.Active
        ? InviteStatus.Accepted
        : InviteStatus.Declined
      : eventInvite
      ? eventInvite.status
      : InviteStatus.Pending
  );
  const { gqlSdk } = useGql();
  const { login } = useLogin(currentUrl);

  const { mutate: updateUserEventInvite, isPending: isUpdatingEventStatus } =
    useUpdateUserEventInvite(eventId);
  const { mutateUserEvent, isUpdatingUserEvent } = useUpdateUserEvent(
    eventId,
    userId
  );

  const handleChange = (e: any, status: InviteStatus) => {
    e.stopPropagation();
    if (!userId) {
      login();
    }
    if (status !== null) {
      // require one to be selected
      setSelectedStatus(status);

      if (userId && eventInvite) {
        // only if there is a invitation
        updateUserEventInvite({ userId, status });
      }

      const activeStatus: Status =
        status == InviteStatus.Accepted ? Status.Active : Status.Inactive;
      mutateUserEvent(activeStatus);
    }
  };

  return (
    <ToggleButtonGroup
      // orientation="vertical"
      color="primary"
      value={selectedStatus}
      exclusive
      onChange={handleChange}
      aria-label="event-status"
      id="invite-status-button"
      style={{ maxHeight: '40px' }}
    >
      <ToggleButton value={InviteStatus.Declined} color="error">
        <EventBusyIcon />
      </ToggleButton>
      <ToggleButton value={InviteStatus.Accepted} color="success">
        <EventAvailableIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
