import * as React from 'react';

import { Button, CircularProgress } from '@mui/material';

import { Status } from '../../generated/graphql';
import useUpdateUserEvent from '../../hooks/mutations/useUpdateUserEvent';
import { useLogin } from '../../hooks/useLogin';

interface SignUpButtonProps {
  userId?: string;
  eventId: string;
  currentUrl: string;
}

export default function SignUpButton({
  userId,
  eventId,
  currentUrl,
}: SignUpButtonProps) {
  const { login } = useLogin(currentUrl);

  const { mutateUserEvent, isUpdatingUserEvent } = useUpdateUserEvent(
    eventId,
    userId
  );

  const handleOnClick = (e: any) => {
    e.stopPropagation();
    if (!userId) {
      login();
    } else {
      mutateUserEvent(Status.Active);
    }
  };

  return (
    <Button
      type="submit"
      variant="contained"
      disabled={isUpdatingUserEvent}
      onClick={handleOnClick}
      startIcon={
        isUpdatingUserEvent ? (
          <CircularProgress color="inherit" size={25} />
        ) : null
      }
      aria-label="join-event"
      style={{ maxHeight: '40px' }}
    >
      join event
    </Button>
  );
}
