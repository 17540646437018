import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import LaunchIcon from '@mui/icons-material/Launch';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import ShareIcon from '@mui/icons-material/Share';
import {
  Button,
  CardContent,
  Stack,
  Typography,
  Chip,
  CardActions,
  Card,
  CardActionArea,
  Box,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import Link from 'next/link';
import { useRouter } from 'next/router';
import QRCode from 'qrcode.react';

import {
  GetLocationsWithEventsQuery,
  Status,
  EventType,
  EventPrivacy,
  InviteStatus,
} from '../../generated/graphql';
import { AuthUser } from '../../hooks/useAuthUser';
import useGql from '../../hooks/useGql';
import { formatEventDateRange } from '../../utils/dates';
import InviteStatusButton, { EventInvite } from '../buttons/InviteStatusButton';
import SignUpButton from '../buttons/SignUpButton';
import EventInviteDialog from '../dialogs/EventInviteDialog';
import FaviconDisplay from '../FaviconDisplay';

type Location = GetLocationsWithEventsQuery['locations'][number];
type Event = Location['events'][number];

interface EventCardProps {
  event: Event;
  user: AuthUser | null;
  locationUrl?: string | null;
  eventInvite?: EventInvite;
  isLink?: boolean;
  showInvite?: boolean;
  showEdit?: boolean;
  showShareButton?: boolean;
}
function EventCard({
  event,
  locationUrl,
  eventInvite,
  user,
  showInvite = true,
  isLink = true,
  showEdit = false,
  showShareButton = false,
}: EventCardProps) {
  const baseUrl = process.env.NEXT_PUBLIC_FULL_PATH as string;
  const router = useRouter();
  const { gqlSdk } = useGql();
  let canSignUp = showInvite;
  let spotsLeft = undefined;
  const { data: userEventCount, isLoading: isLoadingCount } = useQuery({
    queryKey: ['getUserEventInvites', event.id],
    queryFn: () =>
      gqlSdk?.aggregateUserEvent({
        where: {
          eventId: { equals: event.id },
          status: {
            equals: Status.Active,
          },
        },
      }),
    enabled: !!event.id && showInvite && !!gqlSdk,
  });
  const { data: userEventData, isLoading: isLoadingUserEvent } = useQuery({
    queryKey: ['getUserEvent', event.id],
    queryFn: () => {
      if (!user?.id) return null;
      return gqlSdk?.getUserEvent({
        where: {
          userId_eventId: {
            eventId: event.id,
            userId: user.id,
          },
        },
      });
    },
    enabled: !!event.id && !!user?.id && !!gqlSdk,
  });
  const [isInviting, setIsInviting] = React.useState(false);
  const [isQRCodeOpen, setIsQRCodeOpen] = React.useState(false);
  // count users that have accepted

  const usersGoingCount = userEventCount?.aggregateUserEvent._count
    ?._all as number;
  if (event.maxUsersGoing) {
    spotsLeft = event.maxUsersGoing - usersGoingCount;
    canSignUp = canSignUp && spotsLeft > 0;
  }
  const currentUserEventStatus = userEventData?.getUserEvent?.status;

  const isFutureEvent =
    new Date(event.startDate.replace(' ', 'T')) > new Date();

  let showInviteStatusButton = false;
  let showSignUpButton = false;

  if (
    isFutureEvent ||
    (event.type === EventType.Pickup &&
      currentUserEventStatus !== Status.Active)
  ) {
    // show invite status button if event is in the future
    // or if it is a pickup event, which can be joined at any time
    if (
      eventInvite &&
      (canSignUp || eventInvite.status == InviteStatus.Accepted)
    ) {
      // show invite status button if user has been invited or has accepted
      showInviteStatusButton = true;
    } else if (
      currentUserEventStatus &&
      (canSignUp || currentUserEventStatus === Status.Active)
    ) {
      // or show invite status button if user has signed up
      showInviteStatusButton = true;
    } else if (
      canSignUp &&
      (event.privacy === EventPrivacy.Public ||
        event.privacy === EventPrivacy.InviteOnlyAllWLink)
    ) {
      // show sign up button if user can sign up
      showSignUpButton = true;
    }
  }

  if (isLoadingCount && showInvite) {
    return <CircularProgress color="inherit" size={25} />;
  }

  const cardContent = (
    <CardContent>
      <Stack spacing={1}>
        <Stack direction={'row'} spacing={1} justifyContent="space-between">
          <Typography variant="h5" component="div">
            {event.org?.url && (
              <>
                <FaviconDisplay domain={event.org.url} /> {event.org.name}{' '}
              </>
            )}
            {event.name}{' '}
            {event.privacy &&
              event.privacy !== EventPrivacy.Public &&
              event.privacy !== EventPrivacy.ViewOnly && <LockPersonIcon />}
            <Typography sx={{ fontWeight: 'light' }}>
              {formatEventDateRange(
                new Date(event.startDate.replace(' ', 'T')),
                new Date(event.endDate.replace(' ', 'T')),
                'en'
              )}
            </Typography>
          </Typography>
          {showInviteStatusButton && (
            <InviteStatusButton
              userId={user?.sub}
              eventInvite={eventInvite}
              eventId={event.id}
              currentUrl={window.location.href}
              userActiveStatus={currentUserEventStatus}
            />
          )}
          {showSignUpButton && (
            <>
              <SignUpButton
                eventId={event.id}
                userId={user?.sub}
                currentUrl={window.location.href}
              />
            </>
          )}
          <Stack direction="column" spacing={1}>
            {showEdit &&
              (user?.isSuperAdmin ||
                (user && event.createdByUserId === user.sub)) && (
                <IconButton
                  color="inherit"
                  aria-label="edit event"
                  onClick={e => {
                    e.stopPropagation();
                    router.push('/events/edit/' + event.id);
                  }}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
              )}
            {showShareButton && (
              <IconButton
                color="inherit"
                aria-label="share event"
                onClick={() => setIsQRCodeOpen(true)}
                size="large"
              >
                <ShareIcon />
              </IconButton>
            )}
          </Stack>
        </Stack>
        <Typography variant="body2" color="text.secondary">
          {event.description}
        </Typography>
        <Stack direction="row" spacing={1}>
          <Chip label={event.type} size="small" color="secondary" />
          <Chip label={event.gender} size="small" color="secondary" />
          {event.teamSize && (
            <Chip
              label={event.teamSize + "'s"}
              size="small"
              color="secondary"
            />
          )}
        </Stack>
        {showInvite && event.privacy !== EventPrivacy.ViewOnly && (
          <Box paddingTop={2} typography={'subtitle1'}>
            <Button
              onClick={() => setIsInviting(true)} // Handler to open the invite dialog
              color="primary"
              aria-label="invite-users"
              disabled={!showShareButton}
              variant="outlined"
            >
              {usersGoingCount} going
              {spotsLeft !== undefined && <>, {spotsLeft} spots left</>}
            </Button>
          </Box>
        )}
      </Stack>
    </CardContent>
  );

  return (
    <Card className="event-card">
      {isLink ? (
        <CardActionArea>
          <Link href={`/events/${event.id}`}>{cardContent}</Link>
        </CardActionArea>
      ) : (
        <>{cardContent}</>
      )}
      <CardActions>
        <Stack direction="row" spacing={2}>
          {/* <Button size="small">Join</Button> */}
          {event.url && event.url !== locationUrl && (
            <>
              <Link href={event.url} rel="noopener" target="_blank">
                <Button size="small">
                  Learn More
                  <LaunchIcon sx={{ fontSize: 12 }} />
                </Button>
              </Link>
            </>
          )}
        </Stack>
      </CardActions>
      <EventInviteDialog
        eventId={event.id}
        isOpen={isInviting}
        handleClose={() => setIsInviting(false)}
      />
      <Dialog open={isQRCodeOpen} onClose={() => setIsQRCodeOpen(false)}>
        <DialogTitle>Share Event</DialogTitle>
        <DialogContent>
          <QRCode value={`${baseUrl}/events/${event.id}`} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsQRCodeOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default EventCard;
