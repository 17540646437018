import React, { useEffect, useState } from 'react';

import { Capacitor } from '@capacitor/core';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import {
  AppBar,
  Toolbar,
  IconButton,
  Stack,
  Typography,
  Skeleton,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useQuery } from '@tanstack/react-query';
import { SafeArea } from 'capacitor-plugin-safe-area';

import { InviteStatus, SortOrder } from '../../generated/graphql';
import useGql from '../../hooks/useGql';
import { EventEmailInviteForm } from '../forms/EmailInviteForm';
import UsersList from '../lists/UsersList';
interface StatusIcons {
  [userId: string]: JSX.Element;
}

// params for this EmailSubscribeDialog component
interface EventInviteDialogParams {
  eventId: string;
  isOpen: boolean;
  // handleOpen: () => void;
  handleClose: () => void;
}
export default function EventInviteDialog({
  eventId,
  isOpen,
  handleClose,
}: EventInviteDialogParams) {
  const [errorMessage, setErrorMessage] = useState('');
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [insets, setInsets] = useState({
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  });

  useEffect(() => {
    if (Capacitor.getPlatform() === 'ios') {
      SafeArea.getSafeAreaInsets().then(({ insets }) => {
        setInsets(insets);
      });
    }
  }, []);
  const { gqlSdk } = useGql();
  const { data: eventData, isLoading: eventIsLoading } = useQuery({
    queryKey: ['getEventInvitedUsers', eventId],
    queryFn: () =>
      gqlSdk?.getEventInvitedUsers({
        where: {
          id: eventId,
        },
        orderTeamUsersBy: [
          {
            status: SortOrder.Desc,
          },
          {
            user: {
              isPseudo: SortOrder.Asc,
            },
          },
        ],
      }),

    enabled: !!eventId && !!gqlSdk,
  });

  const event = eventData?.event;
  const hasInvitedUsers = event?.invitedUsers?.length != 0;

  const invitedUsers = event?.invitedUsers?.map(invitedUser => ({
    ...invitedUser.user,
    // Use the user's name directly, appending the status
    status: invitedUser.status,
  }));

  const allUserIds = invitedUsers?.map(user => user.id);
  const statusIcons: StatusIcons | undefined =
    invitedUsers?.reduce<StatusIcons>((acc, user) => {
      // Assign an icon based on the user status
      acc[user.id] =
        user.status === InviteStatus.Accepted ? (
          <CheckIcon color="success" />
        ) : user.status === InviteStatus.Declined ? (
          <CloseIcon color="error" />
        ) : (
          <MarkunreadIcon color="warning" />
        );
      return acc;
    }, {});

  return (
    <Dialog
      sx={{
        paddingTop: insets.top,
        paddingLeft: insets.left,
        paddingRight: insets.right,
        paddingBottom: insets.bottom,
      }}
      open={isOpen}
      onClose={handleClose}
      fullScreen={isFullScreen}
    >
      <AppBar sx={{ position: 'relative' }} color="secondary">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* <DialogTitle>invites</DialogTitle> */}
      <DialogContent>
        <Stack spacing={2}>
          {eventIsLoading ? (
            Array.from({ length: 6 }).map((_, index) => (
              <Skeleton key={index} variant="rounded" height={40} />
            ))
          ) : (
            <>
              <h2>invited users</h2>
              {hasInvitedUsers && invitedUsers ? (
                <UsersList users={invitedUsers} statusIcons={statusIcons} />
              ) : (
                <Typography variant="body1">No users invited</Typography>
              )}
              <h2>invite</h2>
              <EventEmailInviteForm
                eventName={event?.name as string}
                eventId={eventId as string}
                excludedUserIds={allUserIds}
                eventStartDate={event?.startDate as string}
              />
            </>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
