import React from 'react';

import Image from 'next/image';
interface FaviconDisplayProps
  extends Omit<React.ComponentProps<typeof Image>, 'src'> {
  domain: string;
}

const FaviconDisplay = ({ domain, ...props }: FaviconDisplayProps) => {
  const faviconUrl = `http://www.google.com/s2/favicons?domain=${domain}&sz=64`;

  return (
    <Image
      src={faviconUrl}
      alt={`${domain} favicon`}
      width={16}
      height={16}
      unoptimized
      {...props}
    />
  );
};

export default FaviconDisplay;
