import { useMutation } from '@tanstack/react-query';

import { queryClient } from '../../api';
import { InviteStatus } from '../../generated/graphql';
import useGql from '../useGql';

interface MutationProps {
  userId: string;
  status: InviteStatus;
}

const useUpdateUserEventInvite = (eventId: string) => {
  const { gqlSdk } = useGql();

  return useMutation({
    mutationFn: async ({ userId, status }: MutationProps) => {
      // lets convert the form raw values to a proper event to save

      return gqlSdk?.updateUserEventInvite({
        data: {
          status: {
            set: status,
          },
        },
        where: {
          userId_eventId: {
            eventId: eventId,
            userId: userId,
          },
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getEventInvites'] });
      queryClient.invalidateQueries({
        queryKey: ['getUserEventInvites', eventId],
      });
      queryClient.invalidateQueries({
        queryKey: ['getUserEventInvite', eventId],
      });
    },
    onError: (error: any) => {
      console.error(error);
    },
  });
};

export default useUpdateUserEventInvite;
