import { useMutation } from '@tanstack/react-query';

import { queryClient } from '../../api';
import { Status } from '../../generated/graphql';
import useGql from '../useGql';

const useUpdateUserEvent = (eventId: string, userId?: string) => {
  // TODO: This is probably the pattern we should start following
  // Makes queries a bit more reuable and keeps components from bloating
  const { gqlSdk } = useGql();

  const { mutate: mutateUserEvent, isPending: isUpdatingUserEvent } =
    useMutation({
      mutationFn: async (status: Status) => {
        if (!eventId || !userId) {
          throw new Error('Event ID or User ID is missing');
        }

        // lets convert the form raw values to a proper event to save
        return gqlSdk?.upsertOneUserEvent({
          where: {
            userId_eventId: {
              eventId: eventId,
              userId: userId,
            },
          },
          create: {
            status: status,
            event: {
              connect: {
                id: eventId,
              },
            },
            user: {
              connect: {
                id: userId,
              },
            },
          },
          update: {
            status: {
              set: status,
            },
            event: {
              connect: {
                id: eventId,
              },
            },
            user: {
              connect: {
                id: userId,
              },
            },
          },
        });
      },

      onSuccess: () => {
        // queryClient.invalidateQueries({ queryKey: ['getUserTeams'] });
        queryClient.invalidateQueries({ queryKey: ['getEventInvites'] });
        queryClient.invalidateQueries({
          queryKey: ['getUserEventInvite', eventId],
        });
        queryClient.invalidateQueries({
          queryKey: ['getTournament', eventId],
        });
        queryClient.invalidateQueries({
          queryKey: ['getEventCard', eventId],
        });
        queryClient.invalidateQueries({
          queryKey: ['getUserEventInvites', eventId],
        });
        queryClient.invalidateQueries({
          queryKey: ['getUsersInQueue', eventId],
        });
        queryClient.invalidateQueries({
          queryKey: ['getUserEvent', eventId],
        });
      },
      onError: (error: any) => {
        console.error(error);
      },
    });

  return { mutateUserEvent, isUpdatingUserEvent };
};

export default useUpdateUserEvent;
