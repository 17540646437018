export function formatRange(d1: Date, d2: Date, locale: string) {
  // we may add this to a util folder
  return new Intl.DateTimeFormat(locale, {
    month: 'long',
    day: 'numeric',
  }).formatRange(d1, d2);
}

export function formatTime(d1: Date, locale: string) {
  // we may add this to a util folder
  return new Intl.DateTimeFormat(locale, {
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(d1);
}
export function formatEventDateRange(d1: Date, d2: Date, locale: string) {
  const isSameDay = d1.toDateString() === d2.toDateString();

  // Check if startDate has a time portion (not midnight)
  const hasStartTime =
    d1.getHours() !== 0 || d1.getMinutes() !== 0 || d1.getSeconds() !== 0;

  if (isSameDay) {
    // If the start and end date are the same, show the time if available
    return hasStartTime ? formatTime(d1, locale) : formatRange(d1, d2, locale);
  } else {
    // If start and end date are different, show the range without time
    return formatRange(d1, d2, locale);
  }
}
